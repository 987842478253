<template>
	<v-fade-transition hide-on-leave>
		<template v-if="loading || loadingParent">
			<v-skeleton-loader key="transitionCard" type="card" class="rounded-xl" />
		</template>
		<template v-else>
			<v-card key="transitionEnded" class="mx-auto flex-grow-1 flex-shrink-0 d-flex flex-column" style="width: 100%" rounded="xl">
				<v-card-title>
					<span>{{ $t('courses.description') }}</span>
					<v-spacer />
					<v-btn icon color="warning" @click="switchEditMode()">
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text class="pb-0">
					<template v-if="editMode">
						<v-form ref="form" v-model="valid">
							<v-textarea
								v-model="description"
								:label="$t('courses.description')"
								:counter="descriptionMaxLength"
								:rules="rules.required"
								outlined
								rounded
								hide-details="auto"
								class="p`b-0"
								auto-grow
							/>
						</v-form>
						<div class="mx-auto d-flex align-center justify-center flex-wrap pt-0 pb-1">
							<v-btn class="px-4" color="primary" large rounded :loading="loading" @click="callUpdateUserDescription()">
								{{ $t('settings.edit') }}
							</v-btn>
						</div>
					</template>
					<template v-else>
						<template v-if="description.length">
							<p class="card-text text-justify mx-4" style="white-space: pre-line">
								{{ description }}
							</p>
						</template>

						<template v-else>
							<div key="noItemsData" class="mx-auto">
								<div class="mt-2 mb-5 text-center">
									<v-icon size="128" role="img">
										mdi-package-variant
									</v-icon>
									<br />
									<span class="text-h6 font-weight-bold">{{ $t('search.empty') }}</span>
								</div>
								<h4 class="m-4 text-center">
									Write a description to explain shortly about yourself to recruiters.
								</h4>
							</div>
						</template>
					</template>
				</v-card-text>
			</v-card>
		</template>
	</v-fade-transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'UserDescription',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false,
			editMode: false,
			valid: false,
			description: '',
			rules: {
				description: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.descriptionMaxLength) || this.$t('rules.length', { length: this.descriptionMaxLength })
				]
			}
		}
	},
	watch: {
		user(val) {
			if (val) this.description = this.user.info.description
		}
	},
	computed: {
		descriptionMaxLength() {
			return 1024
		},
		...mapGetters({
			user: 'users/user'
		})
	},
	created() {
		this.description = this.user.info.description
	},
	methods: {
		switchEditMode() {
			this.editMode = !this.editMode
		},
		callUpdateUserDescription() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			this.updateUserDescription({
				userID: this.user.id,
				description: this.description
			})
				.then(({ success }) => {
					if (success) this.switchEditMode()
				})
				.then(() => {
					this.loading = false
				})
		},
		...mapActions('settings', ['updateUserDescription'])
	}
}
</script>
